$(document).ready(() => {

  // A Function to Get a Cookie
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  autosize($('textarea'));

  const hamburgers = $('.js_hamburger');
  hamburgers.on('click', function() {
    $('body').toggleClass('mobile-menu-open');
  });

  const noCloseDropdown = $('.js-no-close')
  if(noCloseDropdown.length) {
    noCloseDropdown.on('click', '.dropdown-item', function(e){
      e.stopPropagation();
    });
  }

  // File input
  $('.js_file_input').on('change', function() {
    const path = $(this).val();

    if (path.lastIndexOf('\\')){
        var i = path.lastIndexOf('\\')+1;
    }
    else{
        var i = path.lastIndexOf('/')+1;
    }

    var fileName = path.slice(i);
    var filenameField = $(this).next();
    filenameField.val(fileName);
  });

  // Input mask
  const inputMask = $('.js-input-mask')
  if(inputMask.length) {
    inputMask.inputmask("+38 (099) 999-99-99");
  }

  $(document).ready(function() {
    //$('select').niceSelect();
	  $('select').each(function(){
		  if(!$(this).hasClass('not_nice')){
			  $(this).niceSelect();
		  }
	  });
  });
  $('.js-chk-phone').keyup(function(e){
    var value = $(this).val(); 
    var rep = /[\.;":'a-zA-Zа-яА-Я]/; 
    if (rep.test(value)) { 
        value = value.replace(rep, ''); 
		$(this).val(value); 		
	} 
  });
	

  { // Sticky header

    const header  = $('.js-header-sticky'),
          sticky  = header.offset().top,
          content = $('.main');

    $(window).on('scroll', function() {
      if ($(this).scrollTop() > sticky) {
        header.addClass('header_sticky')
        content.css('padding-top', header.height())
      } else {
        header.removeClass('header_sticky')
        content.css('padding-top', 0)
      }
    })
    
  }



  // footer menu dropdown on mobile
  $('.js_footer_menu_dropdown').on('click', function() {
    $(this).toggleClass('active');
    $(this).next('.page-footer__list').slideToggle();
  });



  {

    const form = '.js-search-form',
          list = '.js-search-form__result-list',
          input = $('.js-search-form__input')

    let timeout = 0

      $('.search-form__icon').on('click', function () {
          var form = input.closest('form');
          var url = form.attr('action');
          url += "&search=" + form.find('[name="search"]').val();
          location.href = url;
          return false;
      });

    input.on('keyup', function(e) {

        $(list).html("");

    if (e.keyCode == 13) {
        var form = input.closest('form');
        var url = form.attr('action');
        url += "&search=" + form.find('[name="search"]').val();
        location.href = url;
        return false;
    }
    else {
        if($(this).val() != '' && $(this).val().length > 2) {

            $.ajax({
                url: 'index.php?route=product/search',
                type: 'GET',
                data : 'search='+$(this).val() + '&is_ajax=1' + languageString,
                dataType: "json"
            }).
            done(function (html) {
                console.log(html);
                if(html.success && html.content) {
                    $(list).html(html.content);
                    $(list).addClass('search-form__result-list_active')
                }
            }).
            fail(function (error) {
                console.log(error)
            })
            ;

        } else {
            $(list).removeClass('search-form__result-list_active')
        }
    }
    })

  }

  { // Category mobile 

    const listOpen = $('.js-menu-categories__next'),
          lsitClose = $('.js-menu-categories__back'),
          categoryMenuClose = $('.js-menu-categories__close')

    listOpen.on('click', function() {

      const name = $(this).text();
      
      $(this)
        .next()
          .attr('data-name', name)
          .addClass('menu-categories__children_active')

      return false;
    })

    lsitClose.on('click', function() {
      $(this)
        .parent()
          .removeClass('menu-categories__children_active')
    })  

    categoryMenuClose.on('click', function() {
      $(this).closest('.js-category-list')
        .removeClass('category-list_active')
    })

  }

  { // Popular category dropdown

    const dropdown = $('.js-dropdown-category')

    dropdown.on('click', '.dropdown-item', function() {    
      $(this).siblings().removeClass('active');
    })


  }


  
  { // Main banner

    $('.js-home-banner-main').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      dots: true
    })

    $('.js-home-banner-categories').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      focusOnSelect: true,
      nextArrow: '<div class="slick-arrow slick-arrow-next home-banner-categories__arrow-next"><svg class="slick-arrow-next__icon"><use xlink:href="#slide-arrow-right"></use></svg></div>',
      prevArrow: '<div class="slick-arrow slick-arrow-prev home-banner-categories__arrow-prev"><svg class="slick-arrow-prev__icon"><use xlink:href="#slide-arrow-left"></use></svg></div>',
        responsive: [
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1//
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            },
        ]
    })
  }

   
   { // Main banner

    $('.js-partners').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: '<div class="slick-arrow slick-arrow-next partners__arrow-next"><svg class="slick-arrow-next__icon"><use xlink:href="#slide-arrow-right"></use></svg></div>',
      prevArrow: '<div class="slick-arrow slick-arrow-prev partners__arrow-prev"><svg class="slick-arrow-prev__icon"><use xlink:href="#slide-arrow-left"></use></svg></div>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          }
        },
      ]
    })

  }

  { // Catalog grid
 
    const categoryGrid = $('.js-grid')
    categoryGrid.masonry({
      itemSelector: '.js-grid-item',
      // horizontalOrder: true
    })

    const loadItemsBtn = $('.js-catalog__btn')

    loadItemsBtn.on('click', function() {

      var elem = $(this);

      var page = $(this).data('page');

      var obj = {
          'page' : page,
      }

      $.ajax({
          url: '/all-categories' + languageString,
          method: 'POST',
          data: $.param(obj),
          dataType: 'json'
      }).done(function (data) {          
          const items = $(data.view)

          elem.data('page',data.page);
          if(data.last) {
            elem.hide();
          }

          elem.parent().prev().append(items)

          categoryGrid
              .masonry('appended', items)

          setTimeout(function(){
            categoryGrid
              .masonry('reloadItems')
              .masonry('layout');
          }, 500);
      })

      return false      
    })
 

    categoryGrid.on('click', '.js-load-list', function() {     

      var elem = $(this);

      var childPage = $(this).data('page-child');
      var categoryId = $(this).data('category-id');

      var obj = {
        'childPage' : childPage,
        'categoryId' : categoryId
      }

        var languageCode = getLanguage();
        var languageString = "";
        if(languageCode) {
            languageString = "&language=" + languageCode;
        }

      $.ajax({
          url: '/all-categories' + languageString,
          method: 'POST',
          data: $.param(obj),
          dataType: 'json'
      }).done(function(data) {
          const items = $(data.view);
          elem.data('page-child',data.page);

          elem.closest('.js-grid-item').find('.js-catalog-list')
              .append(items)

          categoryGrid
              .masonry('appended', items)
              .masonry('reloadItems')
              .masonry( 'layout' );

          if(data.last) {
              elem.hide();
          }
      })

      return false      
    })


    // category layout
    function changeLayout() {
      var prodGrid = $('.products-list-grid');
      var prodList = $('.products-list-line');

      var CookieLayout = $.cookie("cat-layout",{
        expires: 7,
        path: '/'
      });

      $('.cat-layout').find('a').removeClass('active');

      if (CookieLayout=='cat-list') {
        $('.cat-layout').find(`[data-layout='cat-list']`).addClass('active');
        prodGrid.hide();
        prodList.show();
      } else {
        $('.cat-layout').find(`[data-layout='cat-grid']`).addClass('active');
        prodGrid.show();
        prodList.hide();
      }
    }

    function getCookieLayout() {
      changeLayout();
    }

    getCookieLayout();

    $('.cat-layout').find('a').on('click', function() {
      var click = $(this);      

      $('.cat-layout').find('a').removeClass('active');

      $.cookie("cat-layout", click.data('layout'), {
        expires: 7,
        path: '/'
      });

      click.addClass('active');

      changeLayout();

      return false;
    });


    { // Catalog toggle

      const menuCatalogButton = $('.js-menu-catalog'),
            catalogList = $('.js-category-list')

      menuCatalogButton.on('click', function() {
        if(catalogList.length) {
          catalogList.toggleClass('category-list_active')
        }
      })

    }

    { // Show all categories

      $('.js_show_all_categories').on('click', function() {
        $(this).prev().addClass('show-all');
      });

    }

    { // Filter

      const filterOpen = $('.js-btn-filter'),
            filterClose = $('.js-filter-close'),
            filterBody = $('.js-filter-product')

      filterOpen.on('click', function() {
        $(this).toggleClass('active');
        filterBody.toggleClass('filter-product_active');
      });

      filterClose.on('click', () => filterBody.removeClass('filter-product_active'))


      const filterItem   = $('.js-filter-product__body'),
            filterSearch = $('.js-filter-search');
      filterItem.on('click', function() {
        const item    = $(this),
              itemTop = item.position().top;


        var action = $('#button-filter').data('action');
        var filter = [];

          document.querySelectorAll('input[name^=\'filter\']:checked').forEach(function(element) {
              filter.push(element.value);
          });

          var ajaxPath = action
          if(filter.length > 0) {
              ajaxPath = action + '&filter=' + filter.join(',') + "&ajax=1";
          }

          $.ajax({
              url: ajaxPath,
              type: "POST",
              dataType: "json",
          })
          .done(function(html) {
              if(html.status == "success") {
                  $('.js-filter-search__count').text(html.count);
                  filterSearch.css({"top": itemTop -121});
                  filterSearch.addClass('show');
              }
          })
          .fail(function(html) {
              console.log(html)
          })
          ;


      });


      const filterSearchClose = $('.js-filter-search__close');
      filterSearchClose.on('click', function() {
        filterSearch.removeClass('show');
      });

      $(document).on('mouseup', function(e) {
        if (!filterSearch.is(e.target) && filterSearch.has(e.target).length === 0 && filterSearch.hasClass('show')) {
          filterSearch.removeClass('show');
        }
      });

    }

    { // Product card: slider      

      $('.js-product-preview-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,  
        asNavFor: '.js-product-preview-nav'
      })

      $('.js-product-preview-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.js-product-preview-for', 
        focusOnSelect: true,
        nextArrow: '<div class="slick-arrow slick-arrow-next product-preview__arrow-next"><svg class="slick-arrow-next__icon"><use xlink:href="#slide-arrow-right"></use></svg></div>',
        prevArrow: '<div class="slick-arrow slick-arrow-prev product-preview__arrow-prev"><svg class="slick-arrow-prev__icon"><use xlink:href="#slide-arrow-left"></use></svg></div>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
      })

    }

    { // Product card: quantity

      $('.js-product-range').on('click', '.js-quantity-field__up', function() {

        let input = $(this).parent().find('.js-quantity-field__input'),
            step = input.data('step'),
            max = input.data('max'),
            value = Number(input.val())

        if(value < max) {
          input.val(value = value + step)
        }
        
      })


        $('.js-product-range').on('change', '.js-quantity-field__input', function() {
            let value = $(this).val();
            let max = $(this).data('max');

            if(value > max) {
                $(this).val(max)
            }
        });


      $('.js-product-range').on('click', '.js-quantity-field__down', function() {

        let input = $(this).parent().find('.js-quantity-field__input'),
            step = input.data('step'),
            value = Number(input.val())

        if(value > 0) {
          input.val(value = value - step)
        }
        
      })

    }

    { // Product card: load more

      $('.js-product-range__load-more').on('click', function() { 
  
        $.ajax({
            url: '/ajax/range-list.php',
            method: 'POST',
        }).done(data => {           
  
            $(this).closest('.js-product-range').find('table tbody')
              .append(data)
  
        })
  
        return false      
      })

    }

    const smoothScroll = (_this, time, offset = 0) => {
      const id  = $(_this).attr('href'),
      top = $(id).offset().top - offset;
      if(top > 0) {
        $('body, html').animate({scrollTop: top}, time);
      }      

    }

    { // Product card: scroll to variants

      const variationsBtn = $('.js-variations-link')

      variationsBtn.on('click', function() {

        event.preventDefault()
        $('#tabs-nav a[href="#tab-range"]').tab('show')
        setTimeout(() => {
          smoothScroll(this, 1500, 135);
        }, 400)       
        
      })

    }


  }


    var languageCode = getLanguage();
    var languageString = "";
    if(languageCode) {
        languageString = "&language=" + languageCode;
    }

  // Registration form
  const personType = $('.js_person_type');

  personType.on('change', function() {
    const i = personType.index($(this));

    $('.js_person_type_field.active').hide().removeClass('active');
    $('.js_person_type_field').eq(i).fadeIn().addClass('active');
  });

  // Checkout
  //const radioControl = $('.js-form-radio-input');

  //radioControl.on('change', function() {
  //  $('.js-form-radio-block').removeClass('active');
  //  $(this).closest('.js-form-radio-block').addClass('active');
  //});




  // Account orders
  const ordersHistory = $('.js_orders_history');

  ordersHistory.on('click', '.js_table_accordeon_btn', function() {
    var accordeonButton = $(this);
    const target = accordeonButton.data('target');

    accordeonButton.toggleClass('active');

    var accordeonContent = ordersHistory.find('[data-accordeon-content="' + target + '"]');
    if (accordeonContent) {
      accordeonContent.slideToggle('slow');
    }
    console.log(accordeonContent);
    return false;
  });
    
  console.log('DOM Loaded')

  /////>>>>>Scripts

    $('#modal-language').on('hidden.bs.modal', function () {

        if(!getCookie("customLanguage")) {
            setCookie('customLanguage', $('select[name="select-language_custom"]').val(), cookieOpt);
            redirectToLang($('select[name="select-language_custom"]').val());
        }
    });


  function getLanguage() {
    var code = getCookie('language');
    if(code != "" && code.indexOf("-") != -1) {
      return code.split("-")[0];
    }
  }


      if(!getCookie("customLanguage")) {

          let cutomLanguage = navigator.language;
          let selectLanguage = $('select[name="select-language_custom"]');
          let selectCurrency = $('select[name="select-currency_custom"]');

          switch (cutomLanguage) {
              case 'ru-RU':
              case 'uk-UA':
              case 'be-BY':
              case 'be-BY':
              case 'kk-KZ':
                  selectLanguage.find('option[value=ru]').attr('selected','selected');
                  selectCurrency.find('option[value=UAH]').attr('selected','selected');
                  $('.js_custom_language_set').text("Подтвердить");
                  break;
              default :
                  selectLanguage.find('option[value=en]').attr('selected','selected');
                  selectCurrency.find('option[value=USD]').attr('selected','selected');
                  $('.js_custom_language_set').text("Submit");

                  break
          }
          $('#modal-language').modal('show');
      }


    let cookieOpt = {
        'path': '/'
    };

  // возвращает cookie с именем name, если есть, если нет, то undefined
  function getCookie(name) {
      var matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  function setCookie(name, value, options) {
      options = options || {};

      var expires = options.expires;

      if (typeof expires == "number" && expires) {
          var d = new Date();
          d.setTime(d.getTime() + expires * 1000);
          expires = options.expires = d;
      }
      if (expires && expires.toUTCString) {
          options.expires = expires.toUTCString();
      }

      value = encodeURIComponent(value);

      var updatedCookie = name + "=" + value;

      for (var propName in options) {
          updatedCookie += "; " + propName;
          var propValue = options[propName];
          if (propValue !== true) {
              updatedCookie += "=" + propValue;
          }
      }

      document.cookie = updatedCookie;
  }

  //close news tab
  $('.js-info-area__close').on('click',function () {
    $(this).parent().parent().hide();
      var date = new Date(new Date().getTime() + 604800 * 1000);
      setCookie('enableTopNewsCookie', '1', { expires : date.toUTCString() })
  });



  $('body').on('click','.js_custom_language_set', function() {

      var lang = $("select[name='select-language_custom']").val();
      var currency = $("select[name='select-currency_custom']").val();
      var action = $("select[name='select-currency_custom']").data('action');

      $.ajax({
          url: action,
          type: 'POST',
          data : 'code=' + currency,
          dataType: "json"
      }).
      done(function (html) {

          if(html.status == "success") {
              setCookie('customLanguage', lang, cookieOpt);
              redirectToLang(lang);
          }
      }).
      fail(function (error) {
          console.log(error)
      })
      ;



  });



  // Select currency
  var currentCurrency = getCookie('currency');
  $('.js-currency-select-text').text(currentCurrency);

  $('.js-currency-select').on('click',function() {

    var currency = $(this).data('currency');
    var action = $(this).data('action');

      $.ajax({
          url: action,
          type: 'POST',
          data : 'code=' + currency,
          dataType: "json"
      }).
      done(function (html) {
        console.log(html)
        if(html.status == "success") {
          location.reload();
        }
      }).
      fail(function (error) {
        console.log(error)
      })
      ;

    return false;
  });
  // End select currency



  // Select language
  var currentLanguage = getCookie('language');

  currentLanguage = currentLanguage.substring(0, currentLanguage.indexOf('-'));
  switch(currentLanguage) {
    case 'en':
      currentLanguage = "English"
      break;
    case 'ru':
      currentLanguage = "Русский"
      break;
    case 'ua':
      currentLanguage = "Українська"
      break;
  }

  $('.js-lang-select-text').text(currentLanguage);

  function redirectToLang (lang) {
      var loc = window.location;
      var path = loc.pathname;

      if(path[0] == '/') {
          path = path.slice(1);
      }
      if(path.indexOf('ru') == '0' || path.indexOf('ua') == '0' || path.indexOf('en') == '0') {
          path = path.slice(2);
      }


      if(path[0] != '/') {
          path = '/' + path;
      }

      // console.log(loc.host);
      if(lang !== 'ru') {
          loc.href = loc.protocol + '//' + loc.hostname + '/' + lang + path;
      }
      else {
          loc.href = loc.protocol + '//' + loc.hostname + path;
      }

      return false;
  }

  $('.js-select-language').on('click', function() {

      var lang = $(this).data('lang');
      redirectToLang(lang);

      return false;

  });
  // End select language



  $('.js-remove-filter').on('click',function() {
    var removeId = $(this).data('remove-id');
    if(removeId) {
        $('input[name^=\'filter\']:checked').each(function(element) {
            if(this.value == removeId) {
              $(this).prop('checked',false);
            }
        });

    }
    else {
        $('input[name^=\'filter\']:checked').each(function(element) {
            $(this).prop('checked',false);
        });
    }
    $('#button-filter').trigger('click');
    return false;
  });

    $('#cart_content').on('click','.modal-btn-minus',function() {
      var currentQ = parseInt($(this).next().val());

      if(currentQ > 1) {
          $(this).next().val(currentQ - 1);
      }

      update($(this).next().attr('name') + "=" + $(this).next().val());

      return false;
    });

    $('#cart_content').on('click','.modal-btn-plus', function() {
        var currentQ = parseInt($(this).prev().val());
        var quantityAll = parseInt($(this).prev().data('max'));

        if(currentQ >= quantityAll) {
            return false;
        }

        $(this).prev().val(currentQ + 1);

        update($(this).prev().attr('name') + "=" + (currentQ + 1));
        return false;
    });

    $('#cart_content').on('change','.js_modal_quant', function() {
      var currentQ = parseInt($(this).val());
      var quantityAll = parseInt($(this).data('max'));

      if(currentQ >= quantityAll) {
          update($(this).attr('name') + "=" + (quantityAll));
          return false;
      }

      update($(this).attr('name') + "=" + (currentQ));

      return false;
    });

    let qty_list_pcb_smt = [5, 10, 15, 20, 25, 30, 50, 75, 100,
        125, 150, 200, 250, 300, 400, 450, 500, 600, 700, 800, 900,
        1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000,
        6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000, 11000, 12000,
        13000, 14000, 15000, 17000, 18000, 19000, 25000, 30000, 40000, 50000,
        60000, 70000, 80000
    ];

    $('#cart_content').on('click','.modal-btn-minus-pcb-smt',function() {
        var currentQ = parseInt($(this).next().val());

        if($(this).next().hasClass('cart_pcb')){
            let array_index = qty_list_pcb_smt.indexOf(currentQ);
            $(this).next().val(qty_list_pcb_smt[array_index - 1]);

            update($(this).next().attr('name') + "=" + (qty_list_pcb_smt[array_index - 1]));
        }else{
            if(currentQ > 1) {
                $(this).next().val(currentQ - 1);
            }

            update($(this).next().attr('name') + "=" + $(this).next().val());
        }
        return false;
    });

    $('#cart_content').on('click','.modal-btn-plus-pcb-smt', function() {
        var currentQ = parseInt($(this).prev().val());
        if($(this).prev().hasClass('cart_pcb')){
            let array_index = qty_list_pcb_smt.indexOf(currentQ);
            $(this).prev().val(qty_list_pcb_smt[array_index + 1]);

            update($(this).prev().attr('name') + "=" + (qty_list_pcb_smt[array_index + 1]));
        }else{
            $(this).prev().val(currentQ + 1);

            update($(this).prev().attr('name') + "=" + (currentQ + 1));
        }
        return false;
    });

    $('#cart_content').on('change','.js_modal_quant_pcb_smt', function() {
        var currentQ = parseInt($(this).val());
        let array_index = qty_list_pcb_smt.indexOf(currentQ);

        if($(this).hasClass('cart_pcb')){
            let qty = 5;
            let oldQuantity = $(this).data('oldQuantity');
            if (oldQuantity < currentQ){
                update($(this).attr('name') + "=" + (qty_list_pcb_smt[array_index - 1]));
            } else if (oldQuantity > currentQ){
                update($(this).attr('name') + "=" + (qty_list_pcb_smt[array_index + 1]));
            }else{
                update($(this).attr('name') + "=" + qty);
            }
        }else{
            update($(this).attr('name') + "=" + (currentQ));
        }



        return false;
    });

    $('.js-get-cart-items').on('click', function() {
        $.ajax({
            url: 'index.php?route=common/header/cartItems' + languageString,
            type: 'POST',
            dataType: "json"
        }).
        done(function (html) {
            if(html.success && html.content) {
                $('.js-cart-elements').html(html.content);
            }
        }).
        fail(function (error) {
            console.log(error)
        });
    });

    $('.page-header__group').on('click','.js-open-cart', function () {
        $.ajax({
            url: 'index.php?route=checkout/cart/index' + languageString,
            type: 'POST',
            dataType: "json"
        }).
        done(function (html) {
            if(html.success && html.content) {
                $('#cart_content').html(html.content);
                $('#modal-basket').modal('show');
            }
        }).
        fail(function (error) {
            console.log(error)
        });

        return false;
    });


    $('#cart_content').on('click','.js-remove-item-cart', function() {

        $.ajax({
            url: 'index.php?route=checkout/cart/remove' + languageString,
            type: 'post',
            data: 'key=' + $(this).data('cart-id'),
            dataType: 'json'

        })
        .done(function(html) {
            if(html.success && html.content) {
                $('#cart_content').html(html.content);
                $('.cart-stats__count').html(html.count);
            }
        });

        return false;
    });

    $('#cart_content').on('click','.js-remove-item-cart-pcb-smt', function() {

        $.ajax({
            url: 'index.php?route=checkout/cart/remove' + languageString,
            type: 'post',
            data: 'key_pcb_smt=' + $(this).data('cart-pcb-smt-id'),
            dataType: 'json'

        })
            .done(function(html) {
                if(html.success && html.content) {
                    $('#cart_content').html(html.content);
                    $('.cart-stats__count').html(html.count);
                }
            });

        return false;
    });

    function update(quantity) {
        $.ajax({
            url: 'index.php?route=checkout/cart/edit' + languageString,
            type: 'post',
            data: quantity,
            dataType: 'json',
        })
        .done(function(html) {
            if(html.success && html.content) {
                $('#cart_content').html(html.content);
                $('.cart-stats__count').html(html.count);
            }
        });
    }

  $('.js-add-to-cart').on('click', function () {

      var obj = {
          product_id : $(this).data('product-id'),
          quantity : $(this).closest('tr').find('.js-quantity-field__input').val(),
          quantityAll : $(this).closest('tr').find('.js-quantity-field__input').data('max'),
      }

      $.ajax({
          url: 'index.php?route=checkout/cart/add' + languageString,
          type: 'POST',
          data : obj,
          dataType: "json"
      }).
      done(function (html) {
          if(html.success && html.content) {
              $('#cart_content').html(html.content);
              $('#modal-basket').modal('show');
              $('.cart-stats__count').html(html.count);
          }
      }).
      fail(function (error) {
          console.log(error)
      });

    return false;
  });

    $('#cart_content, .checkout-order').on('click', '.open-pcb-smt-details', function (){
        let cart_id = $(this).data('cartId');
        $('.info-pcb-smt-' + cart_id).toggleClass('display-attr');
    });

$('.js-reset-filters').on('click',function() {
    var parentElement = $(this).parent().parent().parent();

    var obj = {
        filters : []
    }

    $.ajax({
        url: $(this).data('action'),
        type: 'POST',
        data : obj,
        dataType: "json"
    }).
    done(function (html) {
        console.log(html);
        if(html.status == "success" && html.content) {
            parentElement.next().remove();
            parentElement.after(html.content);
            parentElement.find('input[type=checkbox]').prop('checked',false);
        }
    }).
    fail(function (error) {
        console.log(error)
    })
    ;
});

  $('.js-apply-filters').on('click',function() {
    var parentElement = $(this).parent().parent().parent();
    //console.log(parentElement);
      var filtersElements = parentElement.find("input:checked");

      var filters = [];
    if(filtersElements.length) {
        filtersElements.each(function() {
          filters.push($(this).val());
        });
    }

    var obj = {
      filters : filters
    }

      $.ajax({
             url: $(this).data('action'),
             type: 'POST',
             data : obj,
             dataType: "json"
         }).
         done(function (html) {
           if(html.status == "success" && html.content) {
               parentElement.next().remove();
               parentElement.after(html.content);
           }
         }).
         fail(function (error) {
           console.log(error)
         })
         ;
  });

  $(".pay_button").on('click',function () {
      let button = $(this);
      $.ajax({
          url: 'index.php?route=account/order/payForm',
          type: 'POST',
          data : 'order_id=' + $(this).data('order-id'),
          dataType: "json"
      }).
      done(function (html) {
          if(html.status == 'success' && html.form) {
              let paymentForm = $(html.form);
              button.after(paymentForm);
              paymentForm.submit();
          }
      }).
      fail(function (error) {
          console.log(error)
      })
      ;

      return false;
  });

  $('.product-range__favorite').on('click',function() {
      var product_id = $(this).data('product-id');
      var isLogged = $(this).data('is-login');

      if(isLogged == 0) {
          location.href = $(this).data('login');
          return false;
      }
      var elem = $(this);
      $.ajax({
          url: 'index.php?route=account/wishlist/add' + languageString,
          type: 'post',
          data: 'product_id=' + product_id,
          dataType: 'json'
      })
      .done(function (html) {
          if(html.success) {
              elem.addClass('in_favorite');
          }
      })
      .fail(function () {
          console.log();
      });
      return false;
  });

  //one cl
    $('.oneclick-order__btn').on('click', function(e) {
        let button = $(this);
        e.preventDefault();
        let form = $(this).closest('form');
        if($('.js-input-mask').val() != "") {
            $.ajax({
                url: '/index.php?route=checkout/confirm' + languageString + "&oneclick=1",
                type: 'POST',
                data: form.serialize(),
                dataType: "json"
            }).
            done(function (html) {
                if(html.status == "3") {
                    //location.href = "/index.php?route=checkout/success" + languageString
                    location.href = button.data('action');
                }
            }).
            fail(function (error) {
                console.log(error)
            })
            ;
        }


        return false;
    })

  $('#form-checkout').on('submit', function() {
      $('.checkout-form-require-error').hide();
      var form = $(this);
      var error = false;

      $('.checkout-data-list__item').each(function() {

          var elementValidation = null;
          if($(this).find('.js-form-radio-block').length > 0) {
              elementValidation = $(this).find('.active').find('[required="required"]');
          }
          else {
              elementValidation = $(this).find('input[required="required"]');
          }

          elementValidation.each(function() {
              if($(this).val() == "") {
                  error = true;
                  $('.checkout-form-require-error').show();
                  return false;
              }
          });
      });

      if(!error) {
          $.ajax({
              url: '/index.php?route=checkout/confirm' + languageString,
              type: 'POST',
              data: form.serialize(),
              dataType: "json",
              beforeSend: function(){
                  $('#form-checkout .form-checkout__submit').addClass('loading');
              },
          }).
          done(function (html) {
              if(html.status == "3") {
                if(html.form) {
                    let paymentForm = $(html.form);
                    form.after(paymentForm);
                    paymentForm.submit();
                }
                else {
                    //location.href = "/index.php?route=checkout/success" + languageString
                    location.href = form.data('action');

                }
              }
          }).
          fail(function (error) {
              $('#form-checkout .form-checkout__submit').removeClass('loading');
              console.log(error)
          });
      }


      return false;
  });

    $('#close-cookie-info').on('click', function () {
            $.ajax({
                url: '/index.php?route=common/footer/writeCookiesTermsUse',
                type: 'POST',
                data: 1,
                dataType: "json",
                beforeSend: function(){
                },
            }).
            done(function (html) {
                if(html.status == "1") {
                    $("#cookie-use-info").remove();
                }
            }).
            fail(function (error) {
            });
    });

})
